import sites from "@/api/sites";

// initial state
const state = () => ({
    site: null,
    showLoginNeedModal: false,
})

// getters
const getters = {}

// actions
const actions = {
    getSite({ commit }, domain) {
        commit("setSite", null);
        sites.getSiteByDomain(domain).then((data) => {
            commit("setSite", data);
        });
    },
    setShowLoginNeedModal({commit}) {
        commit("hideOrShowLoginNeedModal");
    }
}

// mutations
const mutations = {
    setSite(state, site) {
        state.site = site;
    },
    hideOrShowLoginNeedModal(state) {
        state.showLoginNeedModal = !state.showLoginNeedModal;
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}