import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'
import { Trans } from '@/plugins/Translation'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:locale?',
    component: {
      template: "<router-view></router-view>"
    },
    beforeEnter: Trans.routeMiddleware,
    redirect: { name: 'home' },
    children: [
      {
        path: '',
        name: 'home',
        component: Home,
        meta: {
        }
      },
      {
        path: 'about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        meta: {
          title: 'О нас'
        }
      },
      {
        path: 'help',
        name: 'help',
        component: () => import(/* webpackChunkName: "help" */ '../views/Help.vue'),
        meta: {
          title: 'Помощь'
        }
      },
      {
        path: 'catalog/:category?/:subCategory?',
        name: 'catalog',
        component: () => import(/* webpackChunkName: "catalog" */ '../views/Catalog.vue'),
        meta: {
          title: 'Каталог сайтов'
        }
      },
      {

        path: 'top/:sortField?/:sortDirection?/:page?',
        name: 'top',
        component: () => import(/* webpackChunkName: "top" */ '../views/Top.vue'),
        meta: {
          title: 'Тор-500'
        },
      },
      {
        path: 'compare',
        name: 'compare',
        component: () => import(/* webpackChunkName: "compare" */ '../views/Compare.vue'),
        meta: {
          title: 'Сравнить'
        }
      },
      {
        path: 'compare/:sites',
        name: 'compare-sites',
        component: () => import(/* webpackChunkName: "compare" */ '../views/Compare.vue'),
        meta: {
          title: 'Сравнить'
        }
      },
      {
        path: 'ext',
        name: 'ext',
        component: {
          template: "<router-view></router-view>"
        },
        redirect: { name: 'ext-download' },
        children: [

          {
            path: 'download',
            name: 'ext-download',
            component: () => import(/* webpackChunkName: "Download" */ '../views/ext/Download.vue'),
            meta: {
              title: 'Download'
            }
          },
          {
            path: 'welcome',
            name: 'ext-welcome',
            component: () => import(/* webpackChunkName: "Welcome" */ '../views/ext/Welcome.vue'),
            meta: {
              title: 'Welcome'
            },
            children: [
              {
                path: 'chrome',
                name: 'ext-chrome',
                component: () => import(/* webpackChunkName: "Chrome" */ '../views/ext/Chrome.vue'),
                meta: {
                  title: 'Chrome'
                },
              },
              {
                path: 'firefox',
                name: 'ext-firefox',
                component: () => import(/* webpackChunkName: "Firefox" */ '../views/ext/Firefox.vue'),
                meta: {
                  title: 'Firefox'
                },
              },
              {
                path: 'edge',
                name: 'ext-edge',
                component: () => import(/* webpackChunkName: "Edge" */ '../views/ext/Edge.vue'),
                meta: {
                  title: 'Edge'
                },
              },
            ],
          },
          {
            path: 'uninstall',
            name: 'ext-uninstall',
            component: () => import(/* webpackChunkName: "Uninstall" */ '../views/ext/Uninstall.vue'),
            meta: {
              title: 'Uninstall'
            }
          }
        ]
      },
      {
        path: 'cabinet',
        name: 'cabinet',
        redirect: { name: 'cabinet-summary' },
        component: () => import(/* webpackChunkName: "cabinet" */ '../views/Cabinet.vue'),
        meta: {
          auth: true,
          title: 'Кабинет'
        },
        children: [
          {
            path: '',
            name: 'cabinet-summary',
            component: () => import(/* webpackChunkName: "summary" */ '../views/cabinet/Summary.vue'),
          },
          {
            path: 'feedbacks',
            name: 'my-feedbacks',
            component: () => import(/* webpackChunkName: "my-feedbacks" */ '../views/cabinet/Feedbacks.vue'),
          },
          {
            path: 'comparisons',
            name: 'comparisons',
            component: () => import(/* webpackChunkName: "comparisons" */ '../views/cabinet/Comparisons.vue'),
          },
          {
            path: 'ownerships',
            name: 'ownerships',
            component: () => import(/* webpackChunkName: "ownerships" */ '../views/cabinet/Ownerships.vue'),
          },
          {
            path: 'subscriptions',
            name: 'subscriptions',
            component: () => import(/* webpackChunkName: "subscriptions" */ '../views/cabinet/Subscriptions.vue'),
          },
        ]
      },
      {
        path: 'sites/:domain',
        name: 'site',
        component: () => import(/* webpackChunkName: "site" */ '../views/Site.vue'),
        meta: {
        }
      },
      {
        path: 'sites/:domain/feedbacks',
        name: 'feedbacks',
        component: () => import(/* webpackChunkName: "feedbacks" */ '../views/Feedbacks.vue'),
        meta: {
          title: 'Отзывы'
        }
      },
      {
        path: 'search',
        name: 'search',
        component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue'),
        meta: {
          title: 'Поиск'
        }
      },
    ]
  },
  {
    path: '*',
    redirect: to => {
      return Trans.defaultLocale + to.fullPath;
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.name == 'top' && from.name == 'top') {
      return false
    }
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },

})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta && record.meta.auth)) {
    store.dispatch("auth/checkAuth").then(() => {
      if (store.getters['auth/isLoggedIn']) {
        next()
        return
      }
      next({ name: 'home' })
    }).catch(() => {
      next({ name: 'home' })
    });
  } else {
    next()
  }
})

router.afterEach((to) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + ' | WebMoney Advisor';
  } else {
    document.title = 'WebMoney Advisor';
  }
});


export default router
