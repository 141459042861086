<template>
  <div class="text-sm mb-4 md:mb-0">
    <button
      @click="switchLocale('en')"
      type="button"
      class="
        inline-flex
        items-center
        hover:bg-gray-200
        dark:hover:bg-blue-400
        px-2
        py-1
        rounded
      "
      :class="{ 'border border-webmoney': currentLocale == 'en' }"
    >
      <span class="w-5 inline-block mr-1"
        ><img src="@/assets/images/en.svg" class="max-w-full max-h-full"
      /></span>
      <span class="inline-block">English</span>
    </button>
    <button
      @click="switchLocale('ru')"
      type="button"
      class="
        inline-flex
        items-center
        ml-1
        hover:bg-gray-200
        dark:hover:bg-blue-400
        px-2
        py-1
        rounded
      "
      :class="{ 'border border-webmoney': currentLocale == 'ru' }"
    >
      <span class="w-5 inline-block mr-1"
        ><img src="@/assets/images/ru.svg" class="max-w-full max-h-full"
      /></span>
      <span class="inline-block">{{ $t("locales.ru") }}</span>
    </button>
  </div>
</template>

<script>
import { Trans } from "@/plugins/Translation";

export default {
  name: "LocaleSwitcher",
  computed: {
    supportedLocales() {
      return Trans.supportedLocales;
    },
    currentLocale() {
      return Trans.currentLocale;
    },
  },
  data() {
    return {
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(","),
    };
  },
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        const to = this.$router.resolve({ params: { locale } });
        return Trans.changeLocale(locale).then(() => {
          this.$router.push(to.location);
        });
      }
    },
  },
};
</script>
