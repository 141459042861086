import axios from "axios";

const api_root = process.env.VUE_APP_API_URL;

export default {
  getFeedbacksBySite(siteId, page) {
    return axios(`${api_root}/feedbacks`, {
      method: 'get', params: {
        page: page,
        site_id: siteId,
      },
      withCredentials: true
    }).then((res) => {
      return res.data.feedbacks;
    });
  },

  getUserFeedbacks(page) {
    const url = `${api_root}/cabinet/feedbacks`;
      return axios.get(url, {withCredentials: true, params: {page: page}}).then((res) => {
      return res.data.feedbacks;
    });
  },

  getUserLastFeedbackForSite(siteId) {
    const url = `${api_root}/cabinet/feedbacks/last_for_site`;

    return axios.get(url, { withCredentials: true, params: { site_id: siteId } }).then((res) => {
      return res.data;
    });
  },

  createOrUpdateFeedback(text, points, siteId) {
    const url = `${api_root}/feedbacks`;
    return axios(url, {
      method: "post",
      withCredentials: true,
      data: {
        text: text,
        points: points,
        site_id: siteId,
      },
    });
  },



};
