<template>
  <div class="relative">
    <form @submit="onSubmit">
      <input
        @keyup.down="highlightNext"
        @keyup.up="highlightPrev"
        type="text"
        :autofocus="autofocus"
        @blur="hideList"
        @focus="showList"
        v-model="query"
        :placeholder="$t('main.siteSearchPlaceholder')"
        class="
          py-2
          pl-10
          pr-4
          w-full
          block
          border
          rounded
          border-gray-300
          dark:border-gray-800
          focus:outline-none focus:ring-1 focus:border-webmoney
          dark:focus:border-blue-400 dark:bg-gray-700
        "
      />
      <div v-if="!loading" class="absolute left-1.5 top-2">
        <search-icon size="1.5x" class="text-gray-400"></search-icon>
      </div>
      <div v-if="loading" class="absolute left-1.5 top-1.5">
        <Spinner />
      </div>
    </form>
    <div
      class="
        absolute
        border-gray-200 border-b border-l border-r
        dark:border-gray-800
        w-full
        bg-white
        dark:bg-gray-700
        z-10
      "
      v-if="listVisible"
    >
      <div
        class="
          flex
          justify-between
          px-4
          py-2
          text-gray-400
          dark:text-white
          text-sm
          bg-gray-50
          dark:bg-gray-700
        "
        v-if="sites && sites.length > 0"
      >
        <div>{{ $t("search.searchResults") }}:</div>
        <div>
          {{ $t("shared.advisor_rank") }}
        </div>
      </div>
      <div
        v-for="(site, index) in sites"
        :key="site.id"
        @mousedown="selectItem(site.domain, index)"
        :class="{ 'bg-gray-100 dark:bg-gray-800': highlighted == index }"
        class="
          py-2
          px-4
          cursor-pointer
          hover:bg-gray-50
          dark:hover:bg-gray-800
          flex
          items-center
        "
      >
        <div v-if="site.icon" class="w-10 h-10 align-middle mr-3">
          <img :src="root + site.icon" class="w-full max-h-full rounded" />
        </div>
        <span v-html="boldString(site.domain, query)"> </span>
        <span class="text-right ml-auto text-gray-400">
          {{ site.advisor_rank || "—" }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import sites from "@/api/sites";
import Spinner from "@/components/Spinner.vue";
import axios from "axios";
import { SearchIcon } from "vue-feather-icons";

export default {
  props: {
    autofocus: Boolean,
    allowCustomSite: Boolean,
    customAction: Boolean,
  },
  data() {
    return {
      query: "",
      root: process.env.VUE_APP_BACKEND_URL,
      sites: [],
      listVisible: false,
      highlighted: null,
      loading: false,
    };
  },
  components: {
    Spinner,
    SearchIcon,
  },
  watch: {
    query: [
      function () {
        this.highlighted = null;
        if (this.query.length > 0) {
          this.searchSitesByDomain(this.extractHostname(this.query));
        } else {
          this.sites = [];
          sites.cancelAllRequests();
        }
      },
    ],
  },
  computed: {
    site() {
      return this.$store.state.search.site;
    },
  },
  methods: {
    extractHostname(url) {
      var hostname;
      //find & remove protocol (http, ftp, etc.) and get hostname
      if (url.indexOf("//") > -1) {
        hostname = url.split("/")[2];
      } else {
        hostname = url.split("/")[0];
      }

      if (hostname.startsWith("www.")) {
        hostname = hostname.slice(4);
      }

      //find & remove port number
      hostname = hostname.split(":")[0];
      //find & remove "?"
      hostname = hostname.split("?")[0];
      return hostname;
    },
    boldString(str, substr) {
      return str.replaceAll(
        substr,
        `<span class='font-bold text-gray-800 dark:text-blue-400'>${substr}</span>`
      );
    },
    domainValidation(domain) {
      return /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(
        domain
      );
    },
    clearList() {
      this.sites = [];
    },
    hideList() {
      this.listVisible = false;
    },
    showList() {
      if (this.sites && this.sites.length > 0) this.listVisible = true;
    },
    highlightNext() {
      if (this.sites && this.sites.length > 0) {
        if (
          (this.highlighted || this.highlighted === 0) &&
          this.highlighted < this.sites.length
        ) {
          this.highlighted += 1;
        } else {
          this.highlighted = 0;
        }
      }
    },
    highlightPrev() {
      if (this.sites.length > 0 && this.highlighted > 0) {
        this.highlighted -= 1;
      } else if (this.highlighted === 0) {
        this.highlighted = null;
      }
    },
    onSubmit(e) {
      if (this.highlighted !== null) {
        this.searchAction(this.sites[this.highlighted].domain);
      } else if (this.allowCustomSite && this.query && this.query.length > 0) {
        this.searchAction(this.extractHostname(this.query));
      }
      this.hideList();
      e.preventDefault();
    },
    selectItem(domain, index) {
      this.highlighted = index;
      this.searchAction(domain);
      this.hideList();
    },
    searchSitesByDomain(query) {
      this.loading = true;
      sites
        .searchSitesByDomain(query)
        .then((data) => {
          this.loading = false;
          this.sites = data;
          this.showList();
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            this.loading = false;
          }
        });
    },
    searchAction(domain) {
      if (this.customAction) {
        this.$emit("onSelected", domain);
      } else if (this.highlighted === null && !this.domainValidation(domain)) {
        this.$router
          .push({ name: "search", query: { q: domain } })
          .catch(() => {});
      } else {
        this.$router.push({ name: "site", params: { domain: domain } });
      }
    },
  },
};
</script>
