import feedbacks from '@/api/feedbacks'

// initial state
const state = () => ({
    list: [],
    page: 0
})

// getters
const getters = {}

// actions
const actions = {
    getUserFeedbacks({ commit }, { page }) {
        feedbacks.getUserFeedbacks(page).then(data => {
            commit("setList", data)
            commit("setPage", page)
        })
    }
}

// mutations
const mutations = {
    setList(state, feedbacks) {
        state.list = feedbacks
    },
    setPage(state, page) {
        state.page = page
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}