import faqs from '../../api/faqs'

// initial state
const state = () => ({
    list: []
})

// getters
const getters = {}

// actions
const actions = {
    getFaqs({ commit }) {
        faqs.getFaqs().then(data => {
            commit("setList", data)
        })
    }
}

// mutations
const mutations = {
    setList(state, faqs) {
        state.list = faqs
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}