import comparisons from '@/api/comparisons'

// initial state
const state = () => ({
    list: []
})

// getters
const getters = {}

// actions
const actions = {
    
    getUserComparisons({ commit }) {
        comparisons.getComparisons().then(data => {
            commit("setList", data)
        })
    },

    addUserComparison({ dispatch }, sites) {
        comparisons.addComparison(sites).then(data => {
            console.log(data)
            dispatch('getUserComparisons')
        })
    },

    removeUserComparison({ dispatch }, comparison) {
        comparisons.removeComparison(comparison).then(data => {
            console.log(data)
            dispatch('getUserComparisons')
        })
    },
}

// mutations
const mutations = {
    setList(state, comparisons) {
        state.list = comparisons
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}