// initial state
const state = () => ({
    theme: null,
})

// getters
const getters = {}

// actions
const actions = {
    setThemeOnCreated({ commit, dispatch }) {
        if (!('theme' in localStorage)) {
            window.matchMedia('(prefers-color-scheme: dark)').matches ?
                commit("setTheme", 'dark') :
                commit("setTheme", 'light');
        } else {
            commit("setTheme", localStorage.getItem("theme"));
        }
        dispatch("addThemeClass");
    },
    userSetTheme({ commit, dispatch}, theme) {
        commit("setTheme", theme);
        localStorage.setItem("theme", theme);
        dispatch("addThemeClass");
    },
    addThemeClass({ state }) {
        const html = document.documentElement;
        state.theme === 'dark' ?
            html.classList.add("dark") :
            html.classList.remove("dark");
    }
}

// mutations
const mutations = {
    setTheme(state, theme) {
        state.theme = theme;
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}