import axios from "axios"

const api_root = process.env.VUE_APP_API_URL
var cancel;
var CancelToken = axios.CancelToken;

export default {
    getSiteByDomain(domain) {
        const url = `${api_root}/sites/${domain}`;
        return axios.get(url, { withCredentials: true })
            .then(res => {
                return res.data
            })
    },
    getSiteChartDataByDomain(domain) {
        const url = `${api_root}/sites/${domain}/chart_data`;
        return axios.get(url, { withCredentials: true })
            .then(res => {
                return res.data
            })
    },
    getSitesTop(sortField, sortDirection, page, filters) {
        const data = {};
        if (sortField) data.sort_field = sortField;
        if (sortDirection) data.sort_direction = sortDirection;
        if (page) data.page = page;
        if (filters) data.filters = filters;
        if (cancel != undefined) {
            cancel();
        }
        return axios(
            `${api_root}/top`,
            {
                method: 'post', 
                data,
                withCredentials: true,
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c;
                }),
            }
        ).then(res => {
            return res.data.data
        })

    },
    searchSitesByDomain(query, page = 1) {
        const url = `${api_root}/sites/search`;
        const data = {
            query,
            page
        }
        if (cancel != undefined) {
            cancel();
        }
        return axios.post(
            url,
            data,
            {
                withCredentials: true,
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c;
                }),
            }
        )
        .then(res => {
            return res.data.data
        })
        .catch(() => {});
    },
    cancelAllRequests() {
        if (cancel != undefined) {
            cancel();
        }
    }
}