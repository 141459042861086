import axios from "axios"

const api_root = process.env.VUE_APP_API_URL

export default {
    getOwnerships() {
        const url = `${api_root}/cabinet/ownerships`;
        return axios.get(url, { withCredentials: true }).then(res => {
            return res.data.data
        })
    },

    getOwnershipFeedbacks(page) {
        const url = `${api_root}/cabinet/ownership_feedbacks`;
        return axios(url, {
            method: 'get', params: {
                page: page
            }, withCredentials: true
        }).then(res => {
            return res.data
        })
    },

    replyToFeedback(text, feedbackId, versionId) {
        const url = `${api_root}/cabinet/ownership_feedbacks/${feedbackId}/reply`;
        return axios(url, {
            method: "post",
            withCredentials: true,
            data: {
                version_id: versionId,
                response: text,
            },
        });
    },


}