<template>
  <div
    class="
      bg-gray-100
      dark:bg-gray-800 dark:text-white
      sticky
      right-0
      left-0
      top-0
      mb-200
      z-50
    "
  >
    <div>
      <nav
        class="
          container
          w-full
          mx-auto
          px-4
          md:px-0
          flex
          justify-between
          items-stretch
          pl-4
        "
      >
        <div
          class="
            w-full
            md:w-8/12
            flex flex-row
            justify-between
            items-center
            pr-4
            py-7
          "
        >
          <div class="flex mr-4">
            <router-link
              :to="$i18nRoute({ name: 'home' })"
              class="inline-flex items-center"
            >
              <span class="dark:hidden w-10 md:mr-3">
                <img
                  src="@/assets/images/logo-mini.svg"
                  class="max-w-full max-h-full"
                />
              </span>
              <span class="hidden dark:inline-block w-10 md:mr-3">
                <img
                  src="@/assets/images/logo-mini-dark.svg"
                  class="max-w-full max-h-full"
                />
              </span>
              <span
                class="
                  hidden
                  md:inline-block
                  uppercase
                  font-bold
                  tracking-widest
                  text-webmoney
                  dark:text-blue-400
                "
                >Advisor</span
              >
            </router-link>
          </div>

          <div class="relative flex-auto">
            <Search allowCustomSite />
          </div>

          <div class="flex md:hidden items-center justify-center">
            <div @click="mobileMenu = !mobileMenu" class="flex-1 pl-4 pr-0">
              <menu-icon size="1.5x" class="m-auto"></menu-icon>
            </div>
          </div>

          <div class="hidden md:block ml-3">
            <ul class="flex flex-row">
              <li class="">
                <router-link
                  :to="$i18nRoute({ name: 'top' })"
                  class="
                    py-2
                    px-3
                    rounded
                    hover:bg-gray-200
                    dark:hover:bg-blue-400
                    whitespace-nowrap
                  "
                  active-class="bg-gray-200 dark:bg-gray-700"
                >
                  {{ $t("nav.top") }}
                </router-link>
              </li>
              <li class="">
                <router-link
                  :to="$i18nRoute({ name: 'catalog' })"
                  class="
                    py-2
                    px-3
                    rounded
                    whitespace-nowrap
                    hover:bg-gray-200
                    dark:hover:bg-blue-400
                  "
                  active-class="bg-gray-200 dark:bg-gray-700"
                >
                  {{ $t("nav.catalog") }}
                </router-link>
              </li>
              <li class="">
                <router-link
                  :to="$i18nRoute({ name: 'about' })"
                  class="
                    py-2
                    px-3
                    rounded
                    whitespace-nowrap
                    hover:bg-gray-200
                    dark:hover:bg-blue-400
                  "
                  active-class="bg-gray-200 dark:bg-gray-700"
                >
                  {{ $t("nav.about") }}
                </router-link>
              </li>
              <li class="">
                <router-link
                  :to="$i18nRoute({ name: 'ext-download' })"
                  class="
                    py-2
                    px-3
                    rounded
                    whitespace-nowrap
                    hover:bg-gray-200
                    dark:hover:bg-blue-400
                  "
                  active-class="bg-gray-200 dark:bg-gray-700"
                >
                  {{ $t("nav.download") }}
                </router-link>
              </li>
              <li class="">
                <router-link
                  :to="$i18nRoute({ name: 'help' })"
                  class="
                    py-2
                    px-3
                    rounded
                    whitespace-nowrap
                    hover:bg-gray-200
                    dark:hover:bg-blue-400
                  "
                  active-class="bg-gray-200 dark:bg-gray-700"
                >
                  {{ $t("nav.help") }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="hidden md:flex items-center" v-if="!isLoggedIn">
          <div class="text-right w-full">
            <a
              :href="root + 'login?back_url=' + $route.path"
              class="
                py-2
                px-4
                align-middle
                bg-webmoney
                text-white
                rounded
                shadow
                hover:bg-blue-400
              "
            >
              {{ $t("nav.login") }}
            </a>
          </div>
        </div>
        <UserSidebar v-if="isLoggedIn" />
      </nav>
    </div>

    <div v-if="mobileMenu" class="fixed top-0 bottom-0 left-0 right-0">
      <div
        @click="mobileMenu = !mobileMenu"
        class="bg-gray-800 bg-opacity-75 inset-0 absolute"
      ></div>

      <div
        class="
          absolute
          bg-white
          dark:bg-gray-800
          left-20
          right-0
          top-0
          bottom-0
        "
      >
        <div class="text-right p-2">
          <div
            class="
              flex
              justify-between
              border-b border-gray-100
              dark:border-gray-700
            "
            v-if="isLoggedIn"
          >
            <button
              @click="logout"
              class="
                p-2
                ml-2
                my-4
                text-gray-400
                rounded
                dark:hover:bg-blue-400 dark:hover:text-gray-300
              "
              :class="{
                'hover:bg-gray-300 hover:text-gray-600': !showUserSidebar,
                'hover:bg-gray-800 hover:text-gray-300': showUserSidebar,
              }"
              :title="$t('nav.logout')"
            >
              <LogOutIcon size="1x" />
            </button>
            <router-link
              :to="$i18nRoute({ name: 'cabinet' })"
              @click.native="mobileMenu = false"
              class="w-full inline-flex items-center justify-end"
            >
              <div class="">
                {{ user.nickname || user.wmid }}
                <div class="text-gray-400 text-sm" v-if="user.nickname">
                  {{ user.wmid }}
                </div>
              </div>
              <div class="w-10 ml-5">
                <img
                  class="max-w-full max-h-full rounded"
                  :src="avatarLink(user.wmid)"
                  :alt="user.wmid"
                />
              </div>
            </router-link>
          </div>

          <a
            v-if="!isLoggedIn"
            @click.native="mobileMenu = false"
            :href="root + 'login?back_url=' + $route.path"
            class="
              py-2
              px-4
              align-middle
              bg-webmoney
              text-white
              rounded
              shadow
              hover:bg-blue-400
            "
          >
            {{ $t("nav.login") }}
          </a>
        </div>
        <div class="">
          <ul class="flex flex-col">
            <li class="">
              <router-link
                :to="$i18nRoute({ name: 'top' })"
                @click.native="mobileMenu = false"
                class="
                  block
                  py-2
                  px-3
                  rounded
                  whitespace-nowrap
                  hover:bg-gray-200
                  dark:hover:bg-blue-400
                  border-b
                  dark:border-gray-700
                "
              >
                {{ $t("nav.top") }}
              </router-link>
            </li>
            <li class="">
              <router-link
                :to="$i18nRoute({ name: 'catalog' })"
                @click.native="mobileMenu = false"
                class="
                  block
                  py-2
                  px-3
                  rounded
                  whitespace-nowrap
                  hover:bg-gray-200
                  dark:hover:bg-blue-400
                  border-b
                  dark:border-gray-700
                "
              >
                {{ $t("nav.catalog") }}
              </router-link>
            </li>
            <li class="">
              <router-link
                :to="$i18nRoute({ name: 'about' })"
                @click.native="mobileMenu = false"
                class="
                  block
                  py-2
                  px-3
                  rounded
                  whitespace-nowrap
                  hover:bg-gray-200
                  dark:hover:bg-blue-400
                  border-b
                  dark:border-gray-700
                "
              >
                {{ $t("nav.about") }}
              </router-link>
            </li>
            <li class="">
              <router-link
                :to="$i18nRoute({ name: 'ext-download' })"
                @click.native="mobileMenu = false"
                class="
                  block
                  py-2
                  px-3
                  rounded
                  whitespace-nowrap
                  hover:bg-gray-200
                  dark:hover:bg-blue-400
                  border-b
                  dark:border-gray-700
                "
              >
                {{ $t("nav.download") }}
              </router-link>
            </li>
            <li class="">
              <router-link
                :to="$i18nRoute({ name: 'help' })"
                @click.native="mobileMenu = false"
                class="
                  block
                  py-2
                  px-3
                  rounded
                  whitespace-nowrap
                  hover:bg-gray-200
                  dark:hover:bg-blue-400
                  border-b
                  dark:border-gray-700
                "
              >
                {{ $t("nav.help") }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Search from "@/components/Search.vue";
import { MenuIcon, LogOutIcon } from "vue-feather-icons";
import UserSidebar from "@/components/UserSidebar.vue";
export default {
  data() {
    return {
      query: "",
      root: process.env.VUE_APP_BACKEND_URL,
      mobileMenu: false,
    };
  },
  components: {
    Search,
    UserSidebar,
    MenuIcon,
    LogOutIcon,
  },
  computed: mapState({
    user: (state) => state.auth.user,
    ...mapGetters({
      authStatus: "auth/authStatus",
      isLoggedIn: "auth/isLoggedIn",
    }),
  }),
  created() {
    this.getCurrentUser();
  },
  watch: {
    $route: ["getCurrentUser"],
  },
  methods: {
    getCurrentUser() {
      this.$store.dispatch("auth/getCurrentUser");
    },
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push("/");
      });
    },
    avatarLink(wmid) {
      return (
        "https://events.web.money/open/avatar.aspx?t=url&s=normal&w=" + wmid
      );
    },
  },
};
</script>

<style >
</style>
