import auth from '../../api/auth'

// initial state
const state = () => ({
    status: '',
    isLoggedIn: false,
    user: {}
})

// getters
const getters = {
    isLoggedIn: state => state.isLoggedIn,
    authStatus: state => state.status,
}

// actions
const actions = {
    checkAuth({ commit }) {
        commit("checkRequest")
        return auth.checkAuth().then(data => {
            commit("checkSuccess", data)
        }).catch(() => {
            commit('checkError')
        })
    },

    getCurrentUser({ commit }) {
        commit("userRequest")
        auth.getCurrentUser().then(data => {
            commit("userSuccess", { user: data })
        }).catch(() => {
            commit('userError')
        })
    },

    logout({ commit, dispatch }) {
        return auth.logout().then(() => {
            commit("logout")
            dispatch("getCurrentUser")
        })
    }
}

// mutations
const mutations = {
    checkRequest(state) {
        state.status = 'loading'
    },
    checkSuccess(state) {
        state.status = 'success'
        state.isLoggedIn = true
    },
    checkError(state) {
        state.status = 'error'
        state.isLoggedIn = false
        state.user = {}
    },

    userRequest(state) {
        state.status = 'loading'
    },

    userSuccess(state, { user }) {
        state.status = 'success'
        state.isLoggedIn = true
        state.user = user
    },
    userError(state) {
        state.isLoggedIn = false
        state.status = 'error'
        state.user = {}
    },

    logout(state) {
        state.status = ''
        state.isLoggedIn = false
        state.user = {}
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}