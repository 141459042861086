import ownerships from '@/api/ownerships'

// initial state
const state = () => ({
    list: [],
    page: 0
})

// getters
const getters = {
    isOwner: (state) => (siteId) => {
        return state.list.find(ownership => ownership.site_id === siteId)
    }
}

// actions
const actions = {
    getUserOwnerships({ commit }, { page }) {
        ownerships.getOwnerships(page).then(data => {
            commit("setList", data)
            commit("setPage", page)
        })
    }
}

// mutations
const mutations = {
    setList(state, ownerships) {
        state.list = ownerships
    },
    setPage(state, page) {
        state.page = page
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}