import Vue from 'vue'
import Vuex from 'vuex'
import search from './modules/search'
import feedbacks from './modules/feedbacks'
import top from './modules/top'
import faqs from './modules/faqs'
import site from './modules/site'
import auth from './modules/auth'
import compare from './modules/compare'
import catalog from './modules/catalog'
import theme from './modules/theme'
import userComparisons from './modules/cabinet/comparisons'
import userFeedbacks from './modules/cabinet/feedbacks'
import userOwnerships from './modules/cabinet/ownerships'
import userSubscriptions from './modules/cabinet/subscriptions'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    search,
    feedbacks,
    top,
    faqs,
    site,
    auth,
    compare,
    catalog,
    theme,
    cabinet: {
      namespaced: true,
      modules: {
        comparisons: userComparisons,
        feedbacks: userFeedbacks,
        ownerships: userOwnerships,
        subscriptions: userSubscriptions,
      }
    }
  }
})
