<template>
  <div>
    <div v-if="!isLoggedIn" class="w-full bg-webmoney">
      <div class="container w-full mx-auto px-4 md:px-0">
        <div class="py-10">
          <div class="md:flex items-center">
            <div class="md:w-2/3 mb-4 md:mb-0">
              <div class="text-white text-center lg:text-left">
                {{ $t("footer.authCallToActionText") }}
              </div>
            </div>

            <div class="md:w-1/3">
              <div class="text-center lg:text-right">
                <a
                  :href="root + 'login?back_url=' + $route.path"
                  class="
                    inline-block
                    bg-white
                    text-webmoney
                    px-8
                    py-4
                    rounded-full
                    hover:bg-blue-400 hover:text-white
                    shadow
                  "
                >
                  <log-in-icon
                    size="1x"
                    class="align-middle inline-block mr-2"
                  ></log-in-icon>

                  <span class="inline-block align-middle">{{
                    $t("footer.authCallToActionButton")
                  }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <footer class="container w-full mx-auto pt-10 pb-5">
        <div class="flex items-center flex-col-reverse md:flex-row">
          <div class="md:w-1/3">
            <router-link
              :to="$i18nRoute({ name: 'home' })"
              class="inline-flex items-center"
            >
              <span class="dark:hidden w-10 mr-3">
                <img
                  src="@/assets/images/logo-mini.svg"
                  class="max-w-full max-h-full"
                />
              </span>
              <span class="dark:inline-block hidden w-10 mr-3">
                <img
                  src="@/assets/images/logo-mini-dark.svg"
                  class="max-w-full max-h-full"
                />
              </span>
              <small class="">&copy; 1998-{{ currentYear }}</small>
            </router-link>
          </div>
          <div class="md:w-1/3 text-center">
            <ul
              class="inline-flex items-center mb-4 md:mb-0 flex-col md:flex-row"
            >
              <li class="">
                <router-link
                  :to="$i18nRoute({ name: 'home' })"
                  class="
                    inline-block
                    rounded
                    py-2
                    px-3
                    hover:bg-gray-200
                    dark:hover:bg-blue-400
                  "
                  >{{ $t("nav.home") }}</router-link
                >
              </li>
              <li class="">
                <router-link
                  :to="$i18nRoute({ name: 'top' })"
                  class="
                    inline-block
                    rounded
                    py-2
                    px-3
                    hover:bg-gray-200
                    dark:hover:bg-blue-400
                  "
                  >{{ $t("nav.top") }}-500</router-link
                >
              </li>
              <li class="">
                <router-link
                  :to="$i18nRoute({ name: 'catalog' })"
                  class="
                    inline-block
                    rounded
                    py-2
                    px-3
                    hover:bg-gray-200
                    dark:hover:bg-blue-400
                  "
                  >{{ $t("nav.catalog") }}</router-link
                >
              </li>
              <li class="">
                <router-link
                  :to="$i18nRoute({ name: 'about' })"
                  class="
                    inline-block
                    rounded
                    py-2
                    px-3
                    hover:bg-gray-200
                    dark:hover:bg-blue-400
                    whitespace-nowrap
                  "
                  >{{ $t("nav.about") }}</router-link
                >
              </li>
              <li class="">
                <router-link
                  :to="$i18nRoute({ name: 'ext-download' })"
                  class="
                    inline-block
                    rounded
                    py-2
                    px-3
                    hover:bg-gray-200
                    dark:hover:bg-blue-400
                  "
                  >{{ $t("nav.download") }}</router-link
                >
              </li>
              <li class="">
                <router-link
                  :to="$i18nRoute({ name: 'help' })"
                  class="
                    inline-block
                    rounded
                    py-2
                    px-3
                    hover:bg-gray-200
                    dark:hover:bg-blue-400
                  "
                  >{{ $t("nav.help") }}</router-link
                >
              </li>
            </ul>
          </div>
          <div
            class="
              md:w-1/3
              text-right
              flex
              md:flex-row
              flex-col
              items-center
              justify-end
              md:space-x-4
            "
          >
            <LocaleSwitcher />
            <ThemeButton />
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import { mapGetters } from "vuex";
import { LogInIcon } from "vue-feather-icons";
import ThemeButton from "@/components/ThemeButton.vue";

export default {
  name: "Footer",
  data() {
    return {
      root: process.env.VUE_APP_BACKEND_URL
    };
  },
  components: {
    LocaleSwitcher,
    LogInIcon,
    ThemeButton
  },
  computed: {
    currentYear: () => new Date().getFullYear(),
    ...mapGetters({
      authStatus: "auth/authStatus",
      isLoggedIn: "auth/isLoggedIn"
    })
  }
};
</script>
