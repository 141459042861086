import catalogApi from '../../api/catalog'

// initial state
const state = () => ({
    sitesList: [],
    categoriesList: [],
    page: 0,
    loading: false,
    filters: {},
    category: null,
    subCategory: null,
    subCategoryTitle: {},
})

// getters
const getters = {
}

// actions
const actions = {
    getDefaultCategories({ commit }) {
        commit("clearCategoryList");
        commit("clearSitesList");
        commit("setCategory", null);
        commit("setSubCategory", null);
        commit("clearSubCategoryTitle");

        catalogApi.getDefaultCategories().then(data => {
            commit("setCategoriesList", data)    
        })
    },
    getCategoryIdBySlug({ commit, state }, slug) {
        commit("showLoading");
        commit("setCategory", null);
        commit("setSubCategory", null);
        if (slug && slug.length > 0) {
            commit("clearCategoryList");
            commit("clearSitesList");
            commit("setPage", 1);
            commit("setCategory", slug);
            commit("clearSubCategoryTitle");

            catalogApi.getSubCategories(slug).then(data => {
                commit("setCategoriesList", data.children);
                commit("setSubCategoryTitle", {title_en: data.title_en, title_ru : data.title});
                return data;
            }).then(data => {
                commit("setFiltersCategory", +data.id);
                catalogApi.getSites(
                    state.page,
                    state.filters
                ).then(data => {
                    commit("setSitesList", data);
                    commit("hideLoading");        
                })
            }).catch(() => {
                commit("deleteFiltersCategory");
                commit("hideLoading"); 
            })
        }
    },
    getSubCategoryIdBySlug({ commit, state }, { category, subCategory }) {
        commit("showLoading"); 
        commit("setCategory", null);
        commit("setSubCategory", null);
        if (category && subCategory && subCategory.length > 0) {
            commit("clearCategoryList");
            commit("clearSitesList");
            commit("setPage", 1);
            commit("setSubCategory", subCategory);
            commit("setCategory", category);
            commit("clearSubCategoryTitle");

            catalogApi.getSubCategories(subCategory).then(data => {
                commit("setCategoriesList", data.children);
                commit("setSubCategoryTitle", {title_en: data.title_en, title_ru : data.title});
                return data;
            }).then(data => {
                commit("setFiltersCategory", +data.id);
                catalogApi.getSites(
                    state.page,
                    state.filters
                ).then(data => {
                    commit("setSitesList", data);      
                    commit("hideLoading"); 
                })
            }).catch(() => {
                commit("deleteFiltersCategory");
                commit("hideLoading"); 
            })
        }
        
    },
    
    loadSites({ commit, state }, page ) {
        commit("setPage", page);
        catalogApi.getSites(
            page, 
            state.filters
        ).then(data => {
            commit("appendList", data);
        })
    },

    clearSitesList({ commit }) {
        commit("setList", []);
    },
    clearCategoryList({ commit }) {
        commit("setCategoriesList", []);
    },
}

// mutations
const mutations = {
    showLoading(state) {
        state.loading = true
    },

    hideLoading(state) {
        state.loading = false
    },

    setPage(state, page) {
        state.page = page
    },
    setCategoriesList(state, categories) {
        state.categoriesList = categories
    },
    setSitesList(state, sites) {
        state.sitesList = sites
    },
    appendList(state, sites) {
        state.sitesList = [...state.sitesList, ...sites]
    },
    clearSitesList(state) {
        state.sitesList = []
    },
    clearCategoryList(state) {
        state.categoriesList = []
    },

    appendSitesList(state, sites) {
        state.sitesList = [...state.sitesList, ...sites]
    },

    setFiltersCategory(state, id) {
        state.filters.category = id;
    },

    deleteFiltersCategory(state) {
        delete state.filters.category;
    },
    setCategory(state, slug) {
         state.category = slug;
    },
    setSubCategory(state, slug) {
         state.subCategory = slug;
    },
    setSubCategoryTitle(state, title) {
         state.subCategoryTitle = Object.assign({}, title);
    },
    clearSubCategoryTitle(state) {
         state.subCategoryTitle = {};
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}