<template>
  <div>
    <input
      type="checkbox"
      name="themeSwitch"
      id="themeSwitch"
      @change="setTheme"
      class="hidden"
    />
    <label
      for="themeSwitch"
      class="
        flex
        justify-between
        py-1
        px-2
        dark:bg-gray-700
        border border-gray-300
        dark:border-gray-700
        rounded-2xl
        relative
        cursor-pointer
      "
    >
      <MoonIcon class="text-yellow-500 fill-current w-6 h-6 mr-3" />
      <SunIcon class="text-yellow-300 fill-current w-6 h-6" />
      <div
        class="
          bg-white
          rounded-full
          absolute
          w-6
          h-6
          top-1
          left-2
          border border-gray-300
          dark:border-gray-700
          shadow-xl
          transition
          duration-300
          transform
        "
        :class="{
          'translate-x-9': theme === 'light',
        }"
      ></div>
    </label>
  </div>
</template>

<script>
import { MoonIcon, SunIcon } from "vue-feather-icons";
import { mapState } from "vuex";
export default {
  components: {
    MoonIcon,
    SunIcon,
  },
  computed: mapState({
    theme: (state) => state.theme.theme,
  }),
  methods: {
    setTheme() {
      this.$store.dispatch(
        "theme/userSetTheme",
        this.theme === "light" ? "dark" : "light"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
