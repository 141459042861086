<template>
  <h1
    class="
      font-bold
      uppercase
      text-2xl
      tracking-tight
      text-webmoney
      dark:text-blue-400
      mb-4
    "
  >
    {{ title }}
  </h1>
</template>

<script>
export default {
  props: ["title"],
};
</script>

<style lang="scss" scoped>
</style>
