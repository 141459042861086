import axios from "axios"

const api_root = process.env.VUE_APP_API_URL

export default {
    getFaqs() {
        const url = `${api_root}/faqs`;
        return axios.get(url, { withCredentials: true })
            .then(res => {
                return res.data.data
            })
    },

}