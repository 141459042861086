<template>
  <div class="">
    <div class="relative pt-20 md:pt-40 pb-20 md:pb-64">
      <div
        class="
          hidden
          md:block
          absolute
          top-0
          left-0
          right-0
          overflow-hidden
          z-0
          h-full
        "
      >
        <div
          class="absolute top-0 left-0 right-0 bottom-0 bg-white opacity-60"
        ></div>
        <video autoplay muted loop class="w-full filter dark:invert">
          <source src="@/assets/video.mp4" type="video/mp4" />
        </video>
      </div>

      <div class="container w-full mx-auto px-4 relative z-10">
        <div class="md:w-1/2 mx-auto">
          <ViewTitle :title="$t('main.title')" class="text-center" />
          <ViewSubtitle
            :subtitle="$t('main.subtitle')"
            class="mb-7 text-center"
          />

          <div class="row">
            <div
              class="
                relative
                bg-gray-100
                pb-3
                pt-2
                px-3
                rounded
                bg-opacity-70
                dark:bg-gray-900
              "
              id="search"
            >
              <p class="mb-1 text-gray-500 dark:text-gray-400">
                {{ $t("main.siteSearchLabel") }}:
              </p>
              <Search autofocus allowCustomSite />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="px-4">
      <div class="container mx-auto md:flex items-center mb-10">
        <div class="md:w-1/3 p-10 md:p-20">
          <img src="@/assets/images/feature-1.svg" />
        </div>
        <div class="md:w-2/3 md:p-10 md:pl-20 text-center md:text-left">
          <h2
            class="
              font-bold
              uppercase
              tracking-tight
              text-2xl text-webmoney
              dark:text-blue-400
              mb-4
            "
          >
            {{ $t("main.feature1.firstTitle") }}<br />
            {{ $t("main.feature1.secondTitle") }}
          </h2>
          <p class="text-xl text-gray-500 dark:text-gray-400 mb-10">
            {{ $t("main.feature1.description") }}
          </p>

          <DownloadExt />
        </div>
      </div>
    </div>

    <div class="bg-gray-100 dark:bg-gray-700 px-4">
      <div class="container mx-auto md:flex items-center mb-10">
        <div class="md:w-2/3 text-center md:text-left">
          <h2
            class="
              font-bold
              uppercase
              tracking-tight
              text-2xl text-webmoney
              dark:text-blue-400
              mb-4
            "
          >
            {{ $t("main.feature2.firstTitle") }}<br />
            {{ $t("main.feature2.secondTitle") }}
          </h2>
          <p class="text-xl text-gray-500 dark:text-gray-400 md:mb-10">
            {{ $t("main.feature2.description") }}
          </p>
        </div>
        <div class="md:w-1/3 p-10 md:p-20">
          <div class="">
            <img src="@/assets/images/feature-2.svg" />
          </div>
        </div>
      </div>
    </div>
    <div class="px-4">
      <div class="container mx-auto md:flex items-center mb-10">
        <div class="md:w-1/3 p-10 md:p-20">
          <img src="@/assets/images/feature-3.svg" />
        </div>
        <div class="md:w-2/3 md:pl-20 text-center md:text-left">
          <h2
            class="
              font-bold
              uppercase
              tracking-tight
              text-2xl text-webmoney
              dark:text-blue-400
              mb-4
            "
          >
            {{ $t("main.feature3.firstTitle") }}<br />
            {{ $t("main.feature3.secondTitle") }}
          </h2>
          <p class="text-xl text-gray-500 dark:text-gray-400 mb-10">
            {{ $t("main.feature3.description") }}
          </p>
        </div>
      </div>
    </div>
    <div class="bg-gray-100 dark:bg-gray-700 px-4">
      <div class="container mx-auto md:flex items-center">
        <div class="md:w-2/3 text-center md:text-left">
          <h2
            class="
              font-bold
              uppercase
              tracking-tight
              text-2xl text-webmoney
              dark:text-blue-400
              mb-4
            "
          >
            {{ $t("main.feature4.firstTitle") }}
            {{ $t("main.feature4.secondTitle") }}
          </h2>
          <p class="text-xl text-gray-500 dark:text-gray-400 mb-10">
            {{ $t("main.feature4.description") }}
          </p>
        </div>
        <div class="md:w-1/3 p-10 md:p-20">
          <img src="@/assets/images/feature-4.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DownloadExt from "@/components/DownloadExt";
import Search from "@/components/Search.vue";
import ViewTitle from "@/components/ViewTitle.vue";
import ViewSubtitle from "@/components/ViewSubtitle.vue";

export default {
  components: {
    Search,
    DownloadExt,
    ViewTitle,
    ViewSubtitle,
  },
  data() {
    return {
      root: process.env.VUE_APP_BACKEND_URL,
    };
  },
  methods: {},
};
</script>
