import sitesApi from "@/api/sites";
import router from '@/router'
// initial state
const state = () => ({
    sitesResults: [],
    sitesList: [],
    showList: false,
    domain: null,
    currentSiteIndex: null,
    page: 1,
    showAddPageButton: false,
    showDomainClearButton: false,
})

// getters
const getters = {}
//helpers
const helpers = {
    extractHostname(url) {
        if (!url) {
            return null;
        }
        let hostname;
        //find & remove protocol (http, ftp, etc.) and get hostname
        if (url.indexOf("//") > -1) {
            hostname = url.split("/")[2];
        } else {
            hostname = url.split("/")[0];
        }

        if (hostname.startsWith("www.")) {
            hostname = hostname.slice(4);
        }

        //find & remove port number
        hostname = hostname.split(":")[0];
        //find & remove "?"
        hostname = hostname.split("?")[0];
        return hostname;
    },
    domainValidation(domain) {
        return /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(domain);
    },
}
// actions
const actions = {
    setDomain({ commit, dispatch }, { query, hideList = false }) {
        let domain = helpers.extractHostname(query);
        if (!domain) {
            commit("setDomain", null);
            commit("setShowDomainClearButton", false);
            dispatch("hideSitesList");
            sitesApi.cancelAllRequests();
            return;
        }
        commit("setDomain", domain);
        commit("setPage", 1);
        commit("setShowDomainClearButton", true);
        hideList ? dispatch("hideSitesList") : dispatch("setSitesList");
    },
    setSitesList({ commit, state, dispatch }) {
        let domain = state.domain;
        commit("setShowList", true)
        if (!domain) {
            dispatch("hideSitesList");
            return;
        }
        sitesApi.searchSitesByDomain(domain, state.page).then(data => {
            if (!data || data.length == 0) {
                dispatch("hideSitesList");
            } else {
                commit("setSitesList", data);
            }
        }).catch(() => {
            dispatch("setListEmptyValues");
        })
    },
    hideSitesList({ commit }) {
        commit("setSitesList", []);
        commit("setShowList", false);
        commit("setCurrentSiteIndex", null);
    },
    formSubmited({ commit, state, dispatch }) {
        let domain = state.domain;
        commit("setPage", 1);
        if ((state.currentSiteIndex === 0 || state.currentSiteIndex > 0) && state.sitesList.length > 0) {
            router.push({
                name: 'site',
                params: {
                    domain: state.sitesList[state.currentSiteIndex].domain
                }
            });
        } else if (helpers.domainValidation(domain)) {
            router.push({
                name: 'site',
                params: {domain}
            });
        } else {
            router.push({
                name: 'search',
                query: { q: domain }
            });
        }
        dispatch("hideSitesList");
    },
    setSitesResults({ commit, state }) {
        if (!state.domain) return;
        sitesApi.searchSitesByDomain(state.domain).then(data => {
            if (!data || data.length == 0) {
                commit("setSitesResults", []);
            } else {
                commit("setSitesResults", data);
                data.length == 10 ?
                    commit("setShowAddPageButton", true) :
                    commit("setShowAddPageButton", false);
            }
        }).catch(() => {
            commit("setShowAddPageButton", false);
        });
    },
    addPage({ commit, state }) {
        sitesApi.searchSitesByDomain(state.domain, state.page + 1).then(data => {
            commit("appendSitesResults", data);
            data.length == 10 ?
                commit("setShowAddPageButton", true) :
                commit("setShowAddPageButton", false);
        }).catch(() => {
            commit("setShowAddPageButton", false);
        });
        commit("setPage", state.page + 1);
    },
    incrCurrentSiteIndex({ commit, state }) {
        if (state.currentSiteIndex === null) {
            commit("setCurrentSiteIndex", 0);
        } else if (state.sitesList.length - 1 > state.currentSiteIndex) {
            commit("setCurrentSiteIndex", state.currentSiteIndex + 1);
        }
    },
    decrCurrentSiteIndex({ commit, state }) {
        if (state.currentSiteIndex === 0) {
            commit("setCurrentSiteIndex", null);
        } else if (state.currentSiteIndex > 0) {
            commit("setCurrentSiteIndex", state.currentSiteIndex - 1);
        }
    },
    clearSitesResults({ commit }) {
        commit("setSitesResults", []);
    },
    hideAddPageButton({ commit }) {
        commit("setShowAddPageButton", false);
    },
    setCurrentSiteIndex({ commit }, index) {
        commit("setCurrentSiteIndex", index);
    }
}

// mutations
const mutations = {
    setSitesResults(state, list) {
        state.sitesResults = list;
    },
    appendSitesResults(state, list) {
        state.sitesResults = [...state.sitesResults, ...list];
    },
    setSitesList(state, list) {
        state.sitesList = list;
    },
    setShowList(state, bool) {
        state.showList = bool;
    },
    setCurrentSiteIndex(state, index) {
        state.currentSiteIndex = index;
    },
    setDomain(state, domain) {
        state.domain = domain;
    },
    setPage(state, page) {
        state.page = page;
    },
    setShowAddPageButton(state, bool) {
        state.showAddPageButton = bool;
    },
    setShowDomainClearButton(state, bool) {
        state.showDomainClearButton = bool;
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}