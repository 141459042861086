<template>
  <div class="py-3 relative hidden md:block">
    <div
      class="flex px-5 bg-gray-200 dark:bg-gray-700 rounded-t-xl"
      :class="{
        'rounded-b-xl ': !showUserSidebar,
        'bg-gray-700': showUserSidebar,
      }"
    >
      <button
        @click="toggleUserSidebar"
        class="
          flex
          my-4
          text-right
          items-center
          focus:outline-none focus:ring-0
        "
      >
        <div
          class="flex-auto"
          :class="{
            'text-gray-100': showUserSidebar,
          }"
        >
          {{ user.nickname || user.wmid }}

          <div class="text-gray-400 text-sm" v-if="user.nickname">
            {{ user.wmid }}
          </div>
        </div>

        <div class="ml-5 flex-1">
          <div class="w-10">
            <img
              class="max-w-full max-h-full rounded"
              :src="avatarLink(user.wmid)"
              :alt="user.wmid"
            />
          </div>
        </div>
      </button>

      <button
        @click="logout"
        class="
          py-2
          px-4
          ml-2
          my-4
          text-gray-400
          rounded
          dark:hover:bg-blue-400 dark:hover:text-gray-300
        "
        :class="{
          'hover:bg-gray-300 hover:text-gray-600': !showUserSidebar,
          'hover:bg-gray-800 hover:text-gray-300': showUserSidebar,
        }"
        :title="$t('nav.logout')"
      >
        <LogOutIcon size="1x" />
      </button>
    </div>
    <div
      v-if="showUserSidebar"
      v-click-outside="hideSidebar"
      class="
        overflow-hidden
        rounded-b-xl
        absolute
        right-0
        left-0
        bg-gray-700
      "
    >
      <div class="flex flex-col justify-between h-full px-5 pt-1 pb-5">
        <div class="flex flex-col">
          <div>
            <router-link
              exact-active-class="bg-gray-800"
              :to="$i18nRoute({ name: 'subscriptions' })"
              class="px-4 py-5 hover:bg-gray-800 block border-b border-gray-600"
              @click.native="hideSidebar"
            >
              <div class="flex items-center justify-between">
                <div class="text-gray-300 text-sm flex items-center">
                  <heart-icon size="1x" class="mr-2"></heart-icon>
                  <span class="text-gray-200">
                    {{ $t("cabinet.subscriptions.subtitle") }}
                  </span>
                </div>
                <span class="text-gray-500">
                  {{ user.subscriptions_count || "" }}
                </span>
              </div>
            </router-link>
          </div>
          <div>
            <router-link
              exact-active-class="bg-gray-800"
              :to="$i18nRoute({ name: 'my-feedbacks' })"
              class="px-4 py-5 hover:bg-gray-800 block border-b border-gray-600"
              @click.native="hideSidebar"
            >
              <div class="flex items-center justify-between">
                <div class="text-gray-300 text-sm flex items-center">
                  <message-circle-icon
                    size="1x"
                    class="mr-2"
                  ></message-circle-icon>
                  <span class="text-gray-200">
                    {{ $t("cabinet.feedbacks.subtitle") }}
                  </span>
                </div>
                <span class="text-gray-500">
                  {{ user.feedbacks_count || "" }}
                </span>
              </div>
            </router-link>
          </div>
          <div>
            <router-link
              exact-active-class="bg-gray-800"
              :to="$i18nRoute({ name: 'comparisons' })"
              class="px-4 py-5 hover:bg-gray-800 block border-b border-gray-600"
              @click.native="hideSidebar"
            >
              <div class="flex items-center justify-between">
                <div class="text-gray-300 text-sm flex items-center">
                  <copy-icon size="1x" class="mr-2"></copy-icon>
                  <span class="text-gray-200">
                    {{ $t("cabinet.comparisons.subtitle") }}
                  </span>
                </div>
                <span class="text-gray-500">
                  {{ user.comparisons_count || "" }}
                </span>
              </div>
            </router-link>
          </div>
          <div>
            <router-link
              exact-active-class="bg-gray-800"
              :to="$i18nRoute({ name: 'ownerships' })"
              class="px-4 py-5 hover:bg-gray-800 block border-gray-600"
              :class="{ 'border-b': user.admin }"
              @click.native="hideSidebar"
            >
              <div class="flex items-center justify-between">
                <div class="text-gray-300 text-sm flex items-center">
                  <globe-icon size="1x" class="mr-2"></globe-icon>
                  <span class="text-gray-200">
                    {{ $t("cabinet.ownerships.subtitle") }}
                  </span>
                </div>
                <span class="text-gray-500">
                  {{ user.ownerships_count || "" }}
                </span>
              </div>
            </router-link>
          </div>
          <div v-if="user.admin">
            <a
              href="https://advisor-admin.web.money/"
              target="_blank"
              class="px-4 py-5 hover:bg-gray-800 block"
            >
              <div class="flex items-center justify-between">
                <div class="text-gray-300 text-sm flex items-center">
                  <ToolIcon size="1x" class="mr-2" />
                  <span class="text-gray-200">
                    {{ $t("cabinet.admin_panel") }}
                  </span>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import {
  MessageCircleIcon,
  HeartIcon,
  CopyIcon,
  GlobeIcon,
  LogOutIcon,
  ToolIcon,
} from "vue-feather-icons";

export default {
  data() {
    return {
      showUserSidebar: false,
    };
  },
  components: {
    MessageCircleIcon,
    HeartIcon,
    CopyIcon,
    GlobeIcon,
    LogOutIcon,
    ToolIcon,
  },
  created() {
    this.getCurrentUser();
  },
  watch: {
    $route: ["getCurrentUser"],
  },
  computed: mapState({
    user: (state) => state.auth.user,
    ...mapGetters({
      authStatus: "auth/authStatus",
      isLoggedIn: "auth/isLoggedIn",
    }),
  }),
  methods: {
    toggleUserSidebar() {
      this.showUserSidebar = !this.showUserSidebar;
    },
    hideSidebar() {
      this.showUserSidebar = false;
    },
    getCurrentUser() {
      this.$store.dispatch("auth/getCurrentUser");
    },
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        window.location.reload(true);
      });
    },
    avatarLink(wmid) {
      return (
        "https://events.web.money/open/avatar.aspx?t=url&s=normal&w=" + wmid
      );
    },
  },
};
</script>
