import Vue from "vue";

Vue.filter("limitText", function (value) {
  if (!value) return "";
  value = value.toString();
  if (value.length <= 300) {
    return value
  }
  return value.substring(0, 300) + "...";
});
