import subscriptions from '@/api/subscriptions'

// initial state
const state = () => ({
    list: [],
    page: 0
})

// getters
const getters = {}

// actions
const actions = {
    getUserSubscriptions({ commit }, { page }) {
        subscriptions.getSubscriptions(page).then(data => {
            commit("setList", data)
            commit("setPage", page)
        })
    },

    subscribe({ dispatch }, domain) {
        subscriptions.subscribe(domain).then(() => {
            dispatch("getUserSubscriptions", 1)
        })
    },

    unsubscribe({ dispatch }, domain) {
        subscriptions.unsubscribe(domain).then(() => {
            dispatch("getUserSubscriptions", 1)
        })
    }

}

// mutations
const mutations = {

    setList(state, subscriptions) {
        state.list = subscriptions
    },
    setPage(state, page) {
        state.page = page
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}