import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as Filters from "./util/filters";
import { i18n } from "./i18n";
import { Trans } from "./plugins/Translation";
import "tailwindcss/tailwind.css";
import Notifications from "vue-notification";
import "@/assets/sass/index.sass";
import axios from "axios";
import VueGtag from "vue-gtag";
import vClickOutside from 'v-click-outside';
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans);
Vue.config.productionTip = false;
Vue.use(Notifications);
Vue.use(vClickOutside)
Vue.use(VueGtag, {
  config: { id: "G-PX84LYTT8V" }
}, router);


axios.interceptors.request.use((config) => {
  config.params = { ...config.params, locale: Trans.currentLocale };
  return config;
});

Sentry.init({
  Vue,
  dsn: "https://ca73dba48f754244a0ec4adb4bfc515f@sentry.com4s.com/2",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["advisor.web.money"],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

new Vue({
  router,
  store,
  i18n,
  components: { Filters },
  render: (h) => h(App),
}).$mount("#app");
