<template>
  <p class="text-xl text-gray-500 dark:text-gray-400 mb-5">
    {{ subtitle }}
  </p>
</template>

<script>
export default {
  props: ["subtitle"],
};
</script>

<style lang="scss" scoped>
</style>