import axios from "axios"
const api_root = process.env.VUE_APP_API_URL

export default {
    getDefaultCategories() {
        const url = `${api_root}/categories`;
        return axios.get(url, { withCredentials: true }).then(res => {
            return res.data.data
        })
    },
    getSubCategories(slug) {
        const url = `${api_root}/categories/${slug}`;
        return axios.get(url, { withCredentials: true }).then(res => {
            return res.data
        })
    },
    getSites(page, filters = {}) {
        return axios(`${api_root}/top`, {
            method: 'post', 
            data: {
                page,
                filters: filters,
            }, 
            withCredentials: true,
        }).then(res => {
            return res.data.data
        })
    },
}