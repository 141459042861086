import axios from "axios"

const api_root = process.env.VUE_APP_API_URL

export default {
    getSubscriptions() {
        const url = `${api_root}/cabinet/subscriptions`;
        return axios.get(url, { withCredentials: true }).then(res => {
            return res.data.data
        })
    },

    getSubscriptionsTimeline(page) {
        const url = `${api_root}/cabinet/subscriptions/timeline`;
        return axios(url, {
            method: 'get', params: {
                page: page
            }, withCredentials: true
        }).then(res => {
            return res.data
        })
    },
    subscribe(domain) {
        const url = `${api_root}/sites/${domain}/subscribe`;
        return axios(url, { method: "post", withCredentials: true })
            .then(res => {
                return res.data
            })
    },
    unsubscribe(domain) {
        const url = `${api_root}/sites/${domain}/unsubscribe`;
        return axios(url, { method: "post", withCredentials: true })
            .then(res => {
                return res.data
            })
    },
}

