<template>
  <div id="app" class="dark:bg-gray-900 dark:text-white min-h-screen">
    <Header />
    <div class="flex">
      <div class="w-full">
        <notifications
          classes="notification"
          position="bottom right"
          group="app"
        />
        <router-view />
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    Header,
    Footer,
  },
  created() {
    this.$store.dispatch("theme/setThemeOnCreated");
  },
  watch: {},
  computed: mapState({
    user: (state) => state.auth.user,
    ...mapGetters({
      authStatus: "auth/authStatus",
      isLoggedIn: "auth/isLoggedIn",
    }),
  }),
  methods: {
    eventsLink(wmid) {
      return "https://events.wmtransfer.com/user/" + wmid;
    },
  },
};
</script>>

