import axios from "axios"

const api_root = process.env.VUE_APP_API_URL

export default {
    getComparisons() {
        const url = `${api_root}/cabinet/comparisons`;
        return axios.get(url, { withCredentials: true }).then(res => {
            return res.data.data
        })
    },
    addComparison(sites) {
        const url = `${api_root}/cabinet/comparisons`;
        return axios(url, {
            method: "post", withCredentials: true, data: {
                sites: sites
            }
        }).then(res => {
            return res.data
        })
    },
    removeComparison(id) {
        const url = `${api_root}/cabinet/comparisons/${id}`;
        return axios(url, { method: "delete", withCredentials: true }).then(res => {
            return res.data
        })
    },
}