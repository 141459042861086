import sites from '../../api/sites'
import router from '@/router/'
import qs from 'qs'

const getDefaultState = () => ({
    list: [],
    page: 1,
    sortDirection: 'asc',
    sortField: 'advisor',
    showSidebar: false,
    showAddPageButton: false,
    clickAddPageButton: false,
    loading: false,
    filters: {
        advisor: {
            from: null,
            to: null
        },
        webmoney: {
            from: null,
            to: null
        },
        alexa: {
            from: null,
            to: null
        },
        users: {
            from: null,
            to: null
        },
        feedbacks: {
            from: null,
            to: null
        },
        subscriptions: {
            from: null,
            to: null
        },
        visits: {
            from: null,
            to: null
        },
        uniq_visits: {
            from: null,
            to: null
        },
        status: [],
    },
})


// initial state
const state = getDefaultState();


// Helpers
const helperMethods = {
    mapFilters(filters) {
        let newObj = {};
        let newObjKeys = Object.keys(filters);
        Object.assign(newObj, filters);
        newObjKeys.forEach((key) => {
            if ('from' in filters[key] && 'to' in filters[key]) {
                newObj[key] = Object.assign({}, filters[key]);
                if (newObj[key].from === null && newObj[key].to === null) {
                    delete newObj[key]
                } else {
                    if (newObj[key].from === null) {
                        delete newObj[key].from 
                    }
                    if (newObj[key].to === null) {
                        delete newObj[key].to 
                    }
                }
            } else if (newObj[key].length == 0 || newObj[key].length === null) {
                delete newObj[key];
            }

        });
        
        return newObj;
    },
    setRouter(state, params) {
        const mapFilters = this.mapFilters(state.filters);
        const filtersToStr = qs.stringify(mapFilters, {encode: false});
        filtersToStr.length > 0 ?
            this.setRouterWithQuery(params, filtersToStr) :
            this.setRouterWithoutQuery(params);
    },
    setRouterWithQuery(params, filters) {
        router.push({
            name: 'top',
            params: params,
            query: {filters}
        }).catch(()=>{})
    },
    setRouterWithoutQuery(params) {
        router.push({
            name: 'top',
            params: params,
        }).catch(()=>{})
    }

}

// getters
const getters = {
    doneTodos: state => {
        return state;
    }
}

// actions
const actions = {
    setFilters({ commit }, filters) {
        commit("setTopFilters", filters)
    },
    clearList({ commit }) {
        commit("setList", [])
    },
    showOrHideSidebar({ commit }) {
        commit("setShowSidebar")
    },
    clearState({ commit }) {
        commit("resetState")
    },
    setState({ commit, state }, params) {
        if (params.sortField) {
            commit("setSortField", params.sortField);
            if (params.sortDirection) {
                commit("setSortDirection", params.sortDirection);
                if (params.page) {
                    commit("setPage", params.page)
                }
            }
        }
        helperMethods.setRouter(state, params);
    },
    getSitesTop({ commit, state }) {
        let mapFilters = helperMethods.mapFilters(state.filters);
        if (!state.clickAddPageButton) commit("showLoading");

        sites.getSitesTop(
                state.sortField,
                state.sortDirection,
                state.page,
                mapFilters
            ).then(data => {
            if (state.clickAddPageButton && state.page > 1) {
                commit("appendList", data);
                commit("clickAddPageButton");
            } else {
                commit("setList", data);
                commit("hideLoading");
            }
            data.length == 10 ?
                commit("showAddPageButton") :
                commit("hideAddPageButton");
            
        }).catch(()=>{
            commit("hideAddPageButton");
            commit("hideLoading");
        })
    },
    addPage({commit, state}) {
        commit("clickAddPageButton");
        commit("setPage", state.page + 1);

        const params = {
            sortField: state.sortField,
            sortDirection: state.sortDirection,
            page: state.page,
        };
        helperMethods.setRouter(state, params);
    },
    getRank({ commit, state }, { name, value, way }) {
        if (value || value !== '') {
            commit('setRank', {key: name, value, way});
        } else {
            commit('setRank', {key: name, value: null, way});
        }
        commit("setPage", 1);
        
        const params = {
            sortField: state.sortField,
            sortDirection: state.sortDirection,
            page: state.page,
        };
        helperMethods.setRouter(state, params);

    },

    getStatus({ commit, state } , { status }) {
        commit("setStatus", status);
        commit("setPage", 1);

        const params = {
            sortField: state.sortField,
            sortDirection: state.sortDirection,
            page: state.page,
        };
        helperMethods.setRouter(state, params);
    },
    setSort({ commit, state }, sortField) {
        if (state.sortField == sortField) {
            commit("setSortDirection", state.sortDirection  == "asc" ? "desc" : "asc");
        } else {
            commit("setSortDirection", "asc");
            commit("setSortField", sortField);
        }
        commit("setPage", 1);

        const params = {
            sortField: state.sortField,
            sortDirection: state.sortDirection,
            page: state.page,
        };
        helperMethods.setRouter(state, params);
    },


}

// mutations
const mutations = {
    showLoading(state) {
        state.loading = true
    },

    hideLoading(state) {
        state.loading = false
    },

    setList(state, sites) {
        state.list = sites
    },

    appendList(state, sites) {
        state.list = [...state.list, ...sites]
    },

    setPage(state, page) {
        state.page = page
    },

    setSortDirection(state, sortDirection) {
        state.sortDirection = sortDirection
    },

    setSortField(state, sortField) {
        state.sortField = sortField
    },
    
    setRank(state, {key, value, way}) {
        state.filters[key][way] = value;
    },
    setStatus(state, status) {
        state.filters.status = status;
    },
    setTopFilters(state, filters) {
        state.filters = filters;
    },
    setShowSidebar(state) {
        state.showSidebar = !state.showSidebar;
    },
    resetState (state) {
        Object.assign(state, getDefaultState());
    },
    showAddPageButton(state) {
        state.showAddPageButton = true;
    },
    hideAddPageButton(state) {
        state.showAddPageButton = false;
    },
    clickAddPageButton(state) {
        state.clickAddPageButton = !state.clickAddPageButton;

    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}