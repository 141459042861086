import sites from '../../api/sites'

import axios from 'axios'

// initial state
const state = () => ({
    sites: []
})

// getters
const getters = {
    anySites: state => state.sites.length > 0,
    sitesSortedByAdvisorRank: state => [...state.sites].sort((a, b) => a.advisor_rank - b.advisor_rank),
    sitesSortedByWebmoneyRank: state => [...state.sites].sort((a, b) => a.webmoney_rank - b.webmoney_rank),
    sitesSortedByUserRating: state => [...state.sites].sort((a, b) => b.average_feedbacks_point - a.average_feedbacks_point),
    sitesSortedByVisitsCount: (state, getters) => [...getters.sitesSortedByAdvisorRank].sort((a, b) => b.chart.uniq_visits_count - a.chart.uniq_visits_count),
    sitesSortedByUniqVisitsCount: (state, getters) => [...getters.sitesSortedByAdvisorRank].sort((a, b) => b.chart.visits_count - a.chart.visits_count),
    totalVisitsCount: state => [...state.sites].reduce(function (a, b) {
        return Number(a) + Number(b.chart.visits_count)
    }, 0),
    totalUniqVisitsCount: state => [...state.sites].reduce(function (a, b) {
        return Number(a) + Number(b.chart.uniq_visits_count)
    }, 0),

}

// actions
const actions = {

    setCompareList({ commit }, sitesList) {
        commit("clearList")
        sitesList.forEach(function (site) {
            axios.all([
                sites.getSiteByDomain(site),
                sites.getSiteChartDataByDomain(site)
            ]
            ).then(axios.spread((...responses) => {
                const data = responses[0]
                const chartData = responses[1]
                data['chart'] = chartData
                commit("addSiteToList", data)
            }))
        })
    }
}

// mutations
const mutations = {

    addSiteToList(state, site) {
        state.sites.push(site)
    },

    clearList(state) {
        state.sites = []
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}