import axios from "axios"

const api_root = process.env.VUE_APP_API_URL
const root = process.env.VUE_APP_BACKEND_URL

export default {
    checkAuth() {
        const url = `${api_root}/cabinet/check`;
        return axios.get(url, { withCredentials: true })
            .then(res => {
                return res.data
            })
    },
    getCurrentUser() {
        const url = `${api_root}/cabinet/me`;
        return axios.get(url, { withCredentials: true })
            .then(res => {
                return res.data
            })
    },

    logout() {
        const url = `${root}logout`;
        return axios.get(url, { withCredentials: true })

    },
}