<template>
  <div>
    <div>
      <a
        :href="link"
        target="_blank"
        class="
          inline-block
          bg-webmoney
          text-white
          px-5
          py-4
          rounded-xl
          hover:bg-blue-400
          font-bold
          uppercase
        "
      >
        <span class="flex items-center">
          <span
            class="mr-2 pr-4 border-r border-white dark:border-gray-700 relative w-24"
          >
            <span class="absolute -top-10 left-0">
              <img
                src="@/assets/images/firefox.svg"
                v-if="browser == 'firefox'"
              />
              <img src="@/assets/images/edge.svg" v-if="browser == 'edge'" />
              <img
                src="@/assets/images/chrome.svg"
                v-if="browser == 'chrome'"
              />
            </span>
          </span>
          <span class="">
            {{ $t("ext.download.downloadButton") }}
            {{ browser }}
          </span>
        </span>
      </a>
    </div>
    <div>
      <button
        class="mt-4 text-sm text-gray-400 hover:underline focus:outline-none"
        @click="scrollToSupportedBrowsers()"
      >
        {{ $t("ext.download.anotherBrowser") }}
      </button>
    </div>
  </div>
</template>

<script>
import Bowser from "bowser";

export default {
  components: {},
  computed: {},
  data() {
    return {
      browser: null,
      link: null
    };
  },
  mounted() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    if (browser.isBrowser("chrome", true)) {
      this.browser = "chrome";
      this.link =
        "https://chrome.google.com/webstore/detail/webmoney-advisor/ecnpogknkjmbicoiindidldgedjibfep";
    } else if (browser.isBrowser("firefox", true)) {
      this.browser = "firefox";
      this.link =
        "https://addons.mozilla.org/en-US/firefox/addon/webmoney-advisor";
    } else if (browser.isBrowser("edge", true)) {
      this.browser = "edge";
      this.link =
        "https://microsoftedge.microsoft.com/addons/detail/onnoahhkfhimhbbdmilbamdhldhppdda";
    } else {
      this.browser = "chrome";
      this.link =
        "https://chrome.google.com/webstore/detail/webmoney-advisor/ecnpogknkjmbicoiindidldgedjibfep";
    }
  },
  methods: {
    scrollToSupportedBrowsers() {
      if (this.$router.currentRoute.name === "ext-download") {
        document.getElementById("supportedBrowsers").scrollIntoView();
      } else {
        this.$router.push({ name: "ext-download", hash: "#supportedBrowsers" });
      }
    }
  }
};
</script>
